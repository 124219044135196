import React from 'react';
import {
  Box,
  makeStyles,
  Popover,
  Paper,
  MenuItem,
  ListItemIcon,
  MenuList,
  Avatar,
} from '@material-ui/core';
import styled from 'styled-components';
import EditIcon from 'mdi-react/PencilOutlineIcon';
import TermsIcon from 'mdi-react/FileSignIcon';
import SignOutIcon from 'mdi-react/LogoutVariantIcon';
import NotificationsIcon from 'mdi-react/NotificationSettingsOutlineIcon';

const StyledPaper = styled(Paper)`
  min-width: 300px !important;
  max-width: 300px !important;
  background-color: #3f3f3f;
`;

const StyledName = styled.div`
  //font-family: 'Montserrat' !important;
  font-size: 17px;
  font-weight: 600;
`;

const StyledUserAvatar = styled.div`
  font-size: 24px;
  background-color: #30feaf;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  display: flex;
  font-weight: 700;
  margin-right: 10px;
  color: #000;
`;

const StyledUserBox = styled.div`
  background-color: #e0e0e0;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  color: #000000;
`;
const StyledItemsBox = styled.div`
  background-color: #f5f5f5;
`;

const StyledEmail = styled.div`
  font-size: 10px;
  color: light-gray;
  margin-top: 3px;
`;
const titleStyle = {
  fontSize: '28px',
};

const StyledListItemText = styled.div`
  font-family: 'Montserrat' !important;
  font-weight: 600 !important;
  padding: 0.3rem 0;
`;

const StyledVersionText = styled.div`
  font-size: 10px;
  font-family: 'Montserrat' !important;
  padding: 0 0 1rem 1rem;
  color: #ababab;
`;

const StyledAvatar = styled(Avatar)`
  cursor: pointer !important;
  width: 40px !important;
  height: 40px !important;
  margin-right: 10px;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'hidden',
    background: theme.background,
  },
}));

function UserPopover(props) {
  const classes = useStyles();
  const {
    id,
    open,
    anchorEl,
    handleClose,
    handleLogout,
    handleEditUser,
    handleShowEditUserModal,
    handleShowNotificationPreferencesModal,
    profilePicture,
  } = props;
  const { firstName, lastName, emailAddress } = JSON.parse(localStorage.getItem('USER_INFO'));
  // const role = JSON.parse(localStorage.getItem('User'));
  // const userRole = role?.role?.at(0);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      classes={{
        paper: classes.paper,
      }}
      style={{ marginTop: '30px' }}
    >
      <StyledPaper>
        <StyledUserBox>
          {/* <StyledUserAvatar>{getInitials(`${firstName} ${lastName}`)}</StyledUserAvatar> */}
          <StyledAvatar src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${profilePicture}`} />
          <Box display="flex" flexDirection="column">
            <StyledName>{`${firstName} ${lastName}`}</StyledName>
            <StyledEmail>{emailAddress}</StyledEmail>
          </Box>
        </StyledUserBox>
        <StyledItemsBox>
          <MenuList dense onClick={handleClose}>
            <MenuItem onClick={() => handleShowEditUserModal()}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <StyledListItemText>Edit Profile</StyledListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleShowNotificationPreferencesModal()}>
              <ListItemIcon>
                <NotificationsIcon />
              </ListItemIcon>
              <StyledListItemText>Notification Preferences</StyledListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.open('https://reveo.com/terms-and-conditions/');
              }}
            >
              <ListItemIcon>
                <TermsIcon />
              </ListItemIcon>
              <StyledListItemText>Terms and Conditions</StyledListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleLogout()}>
              <ListItemIcon>
                <SignOutIcon />
              </ListItemIcon>
              <StyledListItemText>Sign Out</StyledListItemText>
            </MenuItem>
          </MenuList>
          <StyledVersionText>2024.8.29</StyledVersionText>
        </StyledItemsBox>
      </StyledPaper>
    </Popover>
  );
}

export default UserPopover;
