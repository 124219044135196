import React from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Button, CircularProgress, FormControlLabel, Grid, Radio } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { withStyles } from '@material-ui/styles';
import CustomButton from '../CustomButton/CustomButton';
import TransperentButtonTheme from '../../themes/Button/TransperentButtonTheme';
import ButtonLoader from '../ButtonLoader/ButtonLoader';

const StyledUploadLayout = styled.div`
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '0px')};
  width: ${(props) => (props.width ? props.width : '100%')} !important;
  height: ${(props) => (props.height ? props.height : '100%')} !important;
  text-transform: inherit;
  color: #6c6c6c;
  font-family: 'Montserrat';
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & button {
    min-width: unset !important;
    margin-top: 8px !important;
  }

  &:hover {
    & > button:nth-child(2) {
      display: flex;
    }
  }

  & {
    & > button:nth-child(2) {
      display: none;
    }
  }
`;

const StyledUploadLayoutContainer = styled(Button)`
  width: ${(props) => (props.width ? props.width : '100%')} !important;
  height: ${(props) => (props.height ? props.height : '100%')} !important;
  padding: 0 !important;
  cursor: pointer !important;
`;

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    font-size: 3em !important;
    color: #bfbfbf !important;
  }
`;

const StyledTitle = styled.div`
  color: #bfbfbf !important;
  padding: 0.5em 0;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
`;

const GreenRadio = withStyles({
  root: {
    padding: 0,
    '&$checked': {
      color: '#35EDA7',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function SMSImageUpload(props) {
  const {
    title,
    icon,
    handleClick,
    showDeleteOption,
    handleDelete,
    url,
    id,
    showRadioOption,
    width,
    height,
    imgId,
    disabled,
    hideLabel,
    deleteId,
    imageCustomStyle = {},
    isLoading,
  } = props;

  const deleteButton = showDeleteOption ? (
    <ThemeProvider theme={{ ...TransperentButtonTheme, minWidth: width, maxWidth: width }}>
      <CustomButton
        style={{
          position: 'absolute',
          transform: 'translate(0, 130px)',
        }}
        onClick={() => handleDelete(imgId)}
      >
        {deleteId === imgId ? (
          <ButtonLoader />
        ) : (
          <div style={{ fontWeight: 500, fontSize: '.8rem', color: '#c91919' }}>Delete</div>
        )}
      </CustomButton>
    </ThemeProvider>
  ) : null;

  const radioOption = showRadioOption ? (
    <FormControlLabel
      value={id}
      control={<GreenRadio color="default" />}
      label=""
      style={{
        position: 'absolute',
        left: '1em',
        top: '.5em',
        borderRadius: '30px',
      }}
    />
  ) : null;

  const contentContainer = url ? (
    <img
      src={url}
      alt="logo"
      style={{
        width: width,
        objectFit: 'contain',
        objectPosition: 'center',
        height: height,
        ...imageCustomStyle,
      }}
    />
  ) : (
    <div
      style={{
        width: width,
        height: height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        border: '4px dashed #bfbfbf',
        borderRadius: imageCustomStyle.borderRadius,
      }}
    >
      {isLoading ? (
        <StyledIcon>
          <CircularProgress />
        </StyledIcon>
      ) : (
        <StyledIcon>{icon}</StyledIcon>
      )}
      {hideLabel ? null : <StyledTitle>{title}</StyledTitle>}
    </div>
  );

  return (
    <StyledUploadLayout height={height} width={width}>
      <ThemeProvider theme={{ cursor: disabled ? 'text' : 'auto' }}>
        <StyledUploadLayoutContainer
          height={height}
          width={width}
          onClick={(e) => handleClick(e, id)}
          disabled={disabled}
        >
          {contentContainer}
        </StyledUploadLayoutContainer>
      </ThemeProvider>
      {deleteButton}
      {radioOption}
    </StyledUploadLayout>
  );
}

SMSImageUpload.propTypes = {
  title: Proptypes.string,
  icon: Proptypes.node,
  handleClick: Proptypes.func,
  showDeleteOption: Proptypes.bool,
  handleDelete: Proptypes.func,
  url: Proptypes.string,
  id: Proptypes.number,
  showRadioOption: Proptypes.bool,
  width: Proptypes.string,
  height: Proptypes.string,
  imgId: Proptypes.number,
  disabled: Proptypes.bool,
  deleteId: Proptypes.number,
  isLoading: Proptypes.bool,
};

SMSImageUpload.defaultProps = {
  title: 'Upload Logo',
  icon: <PublishIcon />,
  handleClick: () => {},
  showDeleteOption: false,
  handleDelete: () => {},
  url: '',
  id: null,
  showRadioOption: false,
  width: '100%',
  height: '100%',
  disabled: false,
  deleteId: null,
  isLoading: false,
};

export default SMSImageUpload;
