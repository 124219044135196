import React, { useEffect, useContext, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import baseUrl from '../../utils/services/config';
import { ConvertIntoEmployeeTableFormat, errorChecker } from '../../utils/helper/helper';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import getEmployeeColumns from '../../Columns/EmployeeColumns';
import CusotomSearchBox from '../../components/CustomSearchBox/CustomSearchBox';
import { AccountContext } from '../../Stores/AccountStore';
import useAlert from '../../hooks/useAlert';
import CusotmAlert from '../../components/CustomAlert/CustomAlert';
import CancellationPopup from '../../components/Popup/CancellationPopup/CancellationPopup';
import useIconTheme from '../../hooks/useIconTheme';
import { OnBoardingContext } from '../../Stores/OnBoarding';
import ReActivePopup from '../../components/Popup/ReActivePopup/ReActivePopup';
import CustomersList from '../Customers/CustomersList';
import useDebounce from '../../hooks/useDebounce';
import useModal from '../../hooks/useModal';
import EditUserPopup from '../../components/Popup/EditUserPopup/EditUserPopup';

function User(props) {
  const { selectedEmployeeType } = props;
  const { state, dispatch } = useContext(GlobalContext);
  const { selectedBusiness, themeContainer, roles, selectedLoaction } = state;
  const { accountDispatch } = useContext(AccountContext);
  const [showReactiveModal, setShowReactiveModal] = useState(false);
  const [reactiveLoader, setReactiveLoader] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [employeeToEdit, setEmployeeToEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerms, setSearchTerms] = useState({
    page: 0,
    count: 0,
    perPage: 30,
  });
  const [stringSearch, setStringSearch] = useState('');

  const debouncedSearchTerm = useDebounce(stringSearch, 500);
  const editEmployeePopup = useModal();

  const employeeAlert = useAlert();
  const [showModal, setshowModal] = useState(false);
  const [deleteLoader, setDeleteloader] = useState(false);

  const { onBoardingDispatch } = useContext(OnBoardingContext);
  const { iconsTheme } = useIconTheme();

  useEffect(() => {
    localStorage.removeItem('user_row_id');
  }, [selectedEmployeeType, selectedBusiness]);

  const handleEdit = (dataIndex) => {
    setEmployeeToEdit(employee[dataIndex].id);
    editEmployeePopup.handleShowModal();
  };

  const handleDelete = (dataIndex) => {
    localStorage.setItem('delete_row_id', dataIndex);
    setshowModal(true);
  };
  const handleClose = () => {
    setshowModal(false);
    localStorage.removeItem('delete_row_id');
  };

  const handleCloseUserPopup = (editedUser) => {
    setEmployee((prevEmployees) => {
      return prevEmployees.map((employee) =>
        employee.id === editedUser.id ? editedUser : employee,
      );
    });

    editEmployeePopup.handleHideShowModal();
    setEmployeeToEdit(null);
  };

  const showDeletionPopUp = async () => {
    const dataIndex = localStorage.getItem('delete_row_id');
    setDeleteloader(true);
    const { handleSetMessage, handleSetMessageType, handleShow } = employeeAlert;
    const customerId = employee[dataIndex].id;
    const accountsContainerCopy = Array.from(employee);
    accountsContainerCopy.splice(dataIndex, 1);
    const postBody = {
      isDeleted: true,
    };
    try {
      const results = await baseUrl.patch(`/user/${customerId}`, { ...postBody });
      if (results.status === 200) {
        handleSetMessageType('success');
        handleSetMessage('User deleted successfully');
        handleShow(true);
        handleClose();
        accountDispatch({
          type: 'UPDATE_ALL_FIELDS_ACCOUNT_CONTAINER',
          payload: Array.from(accountsContainerCopy),
        });
        setEmployee(accountsContainerCopy);
        setDeleteloader(false);
      }
    } catch (err) {
      setDeleteloader(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  const handleReactive = (dataIndex) => {
    localStorage.setItem('reActive_row_id', dataIndex);
    setShowReactiveModal(true);
  };

  const handleReActiveClose = () => {
    setShowReactiveModal(false);
    localStorage.removeItem('reActive_row_id');
  };

  const showReactivePopUp = async () => {
    const dataIndex = localStorage.getItem('reActive_row_id');
    setReactiveLoader(true);
    const { handleSetMessage, handleSetMessageType, handleShow } = employeeAlert;
    const customerId = employee[dataIndex].id;
    const postBody = {
      isDeleted: false,
    };
    try {
      const results = await baseUrl.patch(`/user/${customerId}`, { ...postBody });
      if (results.status === 200) {
        handleSetMessageType('success');
        handleSetMessage('User Re Activeted successfully');
        handleShow(true);
        handleReActiveClose();
      }
    } catch (err) {
      setDeleteloader(false);
      dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
    }
  };

  useEffect(() => {
    baseUrl
      .get(`/business/list?id=${localStorage.getItem('USER_ID')}&deleted=false&page=0&offset=1000`)
      .then((res) => {
        if (res.data.list.length) {
          dispatch({ type: 'SET_BUSINESS_CONTAINER', payload: Array.from(res.data.list) });
        } else {
          onBoardingDispatch({ type: 'SHOW_POPUP' });
        }
      })
      .catch((err) => {
        dispatch({ type: 'SHOW_ERROR', payload: errorChecker(err) });
      });
    if (selectedBusiness) {
      baseUrl
        .get(`/getuserlocation/${localStorage.getItem('USER_ID')}/${selectedBusiness}`)
        .then((res) => {
          if (!res.data.length) {
            onBoardingDispatch({ type: 'SHOW_POPUP' });
          }
          dispatch({ type: 'SET_LOCATIONS_CONTAINER', payload: Array.from(res.data) });
        });
    }
  }, []);

  const getEmployee = () => {
    const user = localStorage.getItem('USER_ID');
    const order = 'DESC';
    const deleted = selectedEmployeeType;
    const { count, page, perPage } = searchTerms;
    const text = debouncedSearchTerm;
    const business = selectedBusiness;
    const location = selectedLoaction;

    console.log(deleted);

    // eslint-disable-next-line max-len
    const urlParams = `location=${location}&user=${user}&business=${business}&deleted=${deleted}&text=${text}&order=${order}&count=${count}&page=${page}&offset=${perPage}`;
    baseUrl
      .get(`/user/list?${urlParams}`)
      .then((res) => {
        setTimeout(() => {
          setIsLoading(false);
          if (res.data.list.length) {
            setSearchTerms({
              ...searchTerms,
              page: res.data.pageMeta.currentPage + 1,
              count: res.data.pageMeta.total,
            });
            const sortedList = res.data.list.sort((a, b) => a.firstName.localeCompare(b.firstName));
            setEmployee([
              ...employee,
              ...Array.from(ConvertIntoEmployeeTableFormat(sortedList, roles)),
            ]);
          }
        }, 500);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setIsLoading(false);
      });
  };

  const cleanSearchTerms = () => {
    setSearchTerms((prevCounter) => ({
      ...prevCounter,
      page: 0,
      count: 0,
    }));
  };
  const cleanStateEmployee = () => {
    setEmployee([]);
  };
  const handleChangeSearchBox = (e) => {
    setIsLoading(true);
    setEmployee([]);
    cleanSearchTerms();
    setStringSearch(e.target.value);
  };
  const clearSearchParameters = () => {
    setIsLoading(true);
    setEmployee([]);
    cleanSearchTerms();
    setStringSearch('');
  };

  useEffect(() => {
    cleanSearchTerms();
    cleanStateEmployee();
    getEmployee();
  }, [state.selectedLoaction, selectedEmployeeType]);

  useEffect(() => {
    getEmployee();
  }, [debouncedSearchTerm]);
  return (
    <>
      <CusotmAlert
        message={employeeAlert.message}
        handleClose={employeeAlert.handleClose}
        open={employeeAlert.show}
        messageType={employeeAlert.messageType}
      />
      <ThemeProvider theme={{ color: themeContainer.tableSearchBoxColor }}>
        <CusotomSearchBox
          loader={isLoading}
          placeholder="Search users…"
          handleChange={handleChangeSearchBox}
          clearSearch={clearSearchParameters}
          searchValue={stringSearch}
        />
      </ThemeProvider>

      <CustomersList
        data={employee}
        columns={getEmployeeColumns(
          handleEdit,
          handleDelete,
          employee,
          iconsTheme,
          roles,
          handleReactive,
        )}
        getCustomers={getEmployee}
        searchTerms={searchTerms}
        isLoading={isLoading}
      />

      <CancellationPopup
        show={showModal}
        showDeletionPopUp={showDeletionPopUp}
        handleClose={handleClose}
        deleteLoader={deleteLoader}
      />

      <EditUserPopup
        handleClose={handleCloseUserPopup}
        show={editEmployeePopup.showModal}
        employeeToEdit={employeeToEdit}
        setEmployeeToEdit={setEmployeeToEdit}
      />

      <ReActivePopup
        show={showReactiveModal}
        showReactivePopUp={showReactivePopUp}
        handleReActiveClose={handleReActiveClose}
        reactiveLoader={reactiveLoader}
      />
    </>
  );
}

// User.propTypes = {
//   selectedEmployeeType: Proptypes.string.isRequired,
// };

export default React.memo(User);
