import React, { useContext } from 'react';
import Proptypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { GlobalContext } from '../../Stores/GlobalStateStore';

const StyledTableCellContainer = styled.div`
  color: ${(props) => props.theme.color} !important;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

function StyledTableCell({ children }) {
  const { state } = useContext(GlobalContext);
  const { themeContainer } = state;

  return (
    <ThemeProvider theme={{ color: themeContainer.tableRowCellColor }}>
      <StyledTableCellContainer>{children}</StyledTableCellContainer>
    </ThemeProvider>
  );
}

StyledTableCell.propTypes = {
  children: Proptypes.node,
};

export default StyledTableCell;
