import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { GlobalContext } from '../../Stores/GlobalStateStore';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import CusotmAlert from '../../components/CustomAlert/CustomAlert';
import useAlert from '../../hooks/useAlert';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

const EmailHeader = styled.div`
  width: 12.5%;
  font-size: 14px;
  color: #888;
  font-family: Montserrat;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const EmailCell = styled.div`
  width: 12.5%;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Cell = styled.div`
  width: ${(props) => 75 / (props.columnsLength - 1)}%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
`;

const Header = styled.div`
  width: ${(props) => 75 / (props.columnsLength - 1)}%;
  font-size: 14px;
  color: #888;
  font-family: 'Montserrat';
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const CellAction = styled.div`
  width: ${(props) => 75 / (props.columnsLength - 1)}%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  &:hover {
    background-color: #eaeaea;
  }
`;

const Container = styled.div`
  margin-left: 6px;
  position: relative;
`;

const useStyles = makeStyles(() => ({
  row: {
    '&:hover .edit_button': {
      opacity: '1 !important',
      transition: '.2s !important',
    },
    '&:hover .delete_button': {
      opacity: '1 !important',
      transition: '.2s !important',
    },
  },
}));

const CustomersList = ({ data = [], columns = [], getCustomers, isLoading, searchTerms = {} }) => {
  const [bringingMoreResults, setBringingMoreResults] = useState(false);
  const { state } = useContext(GlobalContext);
  const reviewAlert = useAlert();
  const handleScrollDown = () => {
    setBringingMoreResults(true);
  };

  const classes = useStyles();

  const { scrollRef } = useInfiniteScroll(handleScrollDown, 'down', 0);

  const CustomerRow = ({ data, render, isEmail, isLoading }) => {
    // if (!data) {
    //   return <Cell columnsLength={columns.length} />;
    // }
    // if (data && isEmail) {
    //   return <EmailCell>{render?.options?.customBodyRender(data)}</EmailCell>;
    // }

    return (
      <Cell columnsLength={columns.length}>
        {isLoading ? <Skeleton width={'100%'} /> : render?.options?.customBodyRender(data)}
      </Cell>
    );
  };

  // useEffect(() => {
  //   getCustomers();
  // }, []);

  // useEffect(() => {
  //   if (state.selectedLoaction) {
  //     getCustomers();
  //   }
  // }, [state.selectedLoaction]);

  useEffect(() => {
    if (bringingMoreResults) {
      if (searchTerms.count !== data.length) {
        getCustomers();
      }
      if (searchTerms.count === data.length) {
        const { handleShow, handleSetMessage, handleSetMessageType } = reviewAlert;
        handleSetMessageType('success');
        handleSetMessage('End of scroll. No more data available');
        handleShow();
      }

      setBringingMoreResults(false);
    }
  }, [bringingMoreResults]);

  return (
    <Container>
      <CusotmAlert
        message={reviewAlert.message}
        handleClose={reviewAlert.handleClose}
        open={reviewAlert.show}
        messageType={reviewAlert.messageType}
      />

      <Table>
        <Row>
          {columns.map(
            (item, index) => (
              // item.label.toUpperCase() === 'CONTACT' ? (
              //   <EmailHeader key={index}>{item.label.toUpperCase()}</EmailHeader>
              // ) : (
              <Header key={item.name} columnsLength={columns.length}>
                {item.label === 'Action' ? '' : item.label.toUpperCase()}
              </Header>
            ),
            // ),
          )}
        </Row>
      </Table>

      <div
        ref={scrollRef}
        style={{
          height: '70vh',
          overflowY: 'scroll',
        }}
      >
        {!isLoading && data.length === 0 ? (
          <NoDataFound>No Data Found!</NoDataFound>
        ) : (
          data.map((customer, customIndex) => (
            <Row key={customIndex} className={classes.row}>
              {columns.map((column, index) =>
                column.label === 'Action' ? (
                  <CellAction key={column.name} columnsLength={columns.length}>
                    {column?.options?.customBodyRenderLite(customIndex, index)}
                  </CellAction>
                ) : (
                  <CustomerRow
                    key={column.name}
                    isEmail={column.label.toUpperCase() === 'CONTACT'}
                    render={column}
                    data={customer[`${column.name}`]}
                    isLoading={isLoading}
                  />
                ),
              )}
            </Row>
          ))
        )}
      </div>
      {/* {isLoading && <ContainerLoader />} */}
    </Container>
  );
};

export default CustomersList;
